import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/routes/home/home';
import FacultyProfile from './components/routes/academics/departments/faculty';
import Contact from './components/routes/contact/contact';
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AGRI from "../src/components/routes/academics/departments/agri/agri";
import AIDS from "../src/components/routes/academics/departments/aids/aids";
import BIOMEDICAL from "../src/components/routes/academics/departments/biomedical/biomedical";
import CSE from "../src/components/routes/academics/departments/cse/cse";
import IT from "../src/components/routes/academics/departments/it/it";
import EEE from "../src/components/routes/academics/departments/eee/eee";
import MECH from "../src/components/routes/academics/departments/mech/mech";
import ICC from "../src/components/routes/committee/icc/icc";
import GRC from "../src/components/routes/committee/grc/grc";
import SCST from "../src/components/routes/committee/scst/sc-st";
import OBCCELL from "../src/components/routes/committee/obc/obc";
import ANTIRAGGING from "../src/components/routes/committee/anti-ragging/anti-ragging";
import INFRA from "../src/components/routes/infrastructer/infra";
import GALLERY from "../src/components/routes/gallery/gallery";
import PLACEMENT from "../src/components/routes/placement/placement";
import NAAC from "../src/components/routes/naac/naac";
import AICTE from "../src/components/routes/aicteApprovalCopies/aicte";
import ABOUTUS from "../src/components/routes/aboutus/about";
import SCIENCE from "../src/components/routes/academics/departments/science/science";
import CAPACITY from "../src/components/routes/placement/capacityBuilding";
import FEEDBACK from "../src/components/routes/feedback/feedback";
import IQAC from "../src/components/routes/iqac/iqac";




import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<ABOUTUS />} />
          <Route path="/faculty-profile" element={<FacultyProfile />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/department-details/agri" element={<AGRI />} />
          <Route path="/department-details/aids" element={<AIDS />} />
          <Route path="/department-details/scienc-and-humanities" element={<SCIENCE />} />

          <Route
            path="/department-details/biomedical"
            element={<BIOMEDICAL />}
          />
          <Route path="/department-details/cse" element={<CSE />} />
          <Route path="/department-details/it" element={<IT />} />
          <Route path="/department-details/eee" element={<EEE />} />
          <Route path="/department-details/mech" element={<MECH />} />
          <Route path="/icc" element={<ICC />} />
          <Route path="/grc" element={<GRC />} />
          <Route path="/anti-ragging" element={<ANTIRAGGING />} />
          <Route path="/obc-cell" element={<OBCCELL />} />
          <Route path="/sc-st" element={<SCST />} /> 
          <Route path="/infrastructure" element={<INFRA />} /> 
          <Route path="/gallery" element={<GALLERY />} /> 
          <Route path="/placement" element={<PLACEMENT />} /> 
          <Route path="/naac" element={<NAAC />} /> 
          <Route path="/aicte" element={<AICTE />} /> 
          <Route path="/capacity_building" element={<CAPACITY />} /> 
          <Route path="/feedback" element={<FEEDBACK />} /> 
          <Route path="/iqac" element={<IQAC />} /> 

        </Routes>

        <a
          href="https://wa.me/1234567890" // Replace with your WhatsApp number
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp-icon"
        >
          <FontAwesomeIcon icon={faWhatsapp} />
        </a>
      </div>
    </Router>
  );
}

export default App;
