import React from 'react';
import {  Carousel } from 'react-bootstrap';
import './header.css'; // Import CSS for styling
// import image1 from '../../../assests/images/6.jpg'; // Example images for the carousel
// import image2 from '../../../assests/images/7.jpg';
// import image3 from '../../../assests/images/8.jpg';
import image1 from "../../../assests/images/11.png";
import image2 from "../../../assests/images/clg-bg.jpg";

const Section = () => {
  return (
    <div className='images'>
        <Carousel>
          <Carousel.Item>
            <img className="d-block w-100" src={image2} alt="First slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={image1} alt="Second slide" />
          </Carousel.Item>
          {/* <Carousel.Item>
            <img className="d-block w-100" src={image3} alt="Third slide" />
          </Carousel.Item> */}
        </Carousel>
    </div>
  );
};

export default Section;
