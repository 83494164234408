import React, { useState } from 'react';
import Header from '../../commons/header/header';
import Footer from '../../commons/footer/footer';
import './gallery.css'; // Ensure you have a CSS file for styling
import image1 from "../../../assests/images/aids-bg.jpg";
import image2 from "../../../assests/images/bg-cse.jpg";
import image3 from "../../../assests/images/agri-bg.jpg";
import image4 from "../../../assests/images/1.png";
import image5 from "../../../assests/images/2.png";
import image6 from "../../../assests/images/3.png";
import image7 from "../../../assests/images/4.png";
import image8 from "../../../assests/images/5.png";
import image9 from "../../../assests/images/6.png";
import image10 from "../../../assests/images/7.png";
// import image11 from "../../../assests/images/8.png";
import image12 from "../../../assests/images/9.png";
import image13 from "../../../assests/images/10.png";
import image14 from "../../../assests/images/8.png";
import image15 from "../../../assests/images/12.png";
import image16 from "../../../assests/images/13.png";
import image17 from "../../../assests/images/14.png";
import image18 from "../../../assests/images/15.png";

import image19 from "../../../assests/images/background-1.jpg";
import image20 from "../../../assests/images/biomedical-bg.jpg";


const images = [
  { src: image1, alt: "Image 1", category: "Category 1" },
  { src: image2, alt: "Image 2", category: "Category 2" },
  { src: image3, alt: "Image 3", category: "Category 1" },
  { src: image4, alt: "Image 3", category: "Category 2" },
  { src: image5, alt: "Image 3", category: "Category 1" },
  { src: image6, alt: "Image 3", category: "Category 1" },
  { src: image7, alt: "Image 3", category: "Category 2" },
  { src: image8, alt: "Image 3", category: "Category 1" },
  { src: image9, alt: "Image 3", category: "Category 2" },
  { src: image10, alt: "Image 3", category: "Category 1" },
  // { src: image11, alt: "Image 3", category: "Category 1" },
  { src: image12, alt: "Image 3", category: "Category 2" },
  { src: image13, alt: "Image 3", category: "Category 1" },
  { src: image14, alt: "Image 3", category: "Category 2" },
  { src: image15, alt: "Image 3", category: "Category 1" },
  { src: image16, alt: "Image 3", category: "Category 2" },
  { src: image17, alt: "Image 3", category: "Category 1" },
  { src: image18, alt: "Image 3", category: "Category 2" },
  { src: image19, alt: "Image 3", category: "Category 1" },
  { src: image20, alt: "Image 3", category: "Category 2" },

  // Add more images with different categories
];

const Gallery = () => {
  const [filter, setFilter] = useState("All");

  const handleFilterChange = (category) => {
    setFilter(category);
  };

  const filteredImages = filter === "All" ? images : images.filter(image => image.category === filter);

  return (
      <>
      <Header />
      <div className="image-section">
        <img src={image3} alt="Gallery" className="department-image" />
        <div className="overlay-text">Gallery</div>
      </div>
      <div className="gallery-menu">
        <button onClick={() => handleFilterChange("All")}>All</button>
        <button onClick={() => handleFilterChange("Category 1")}>Category 1</button>
        <button onClick={() => handleFilterChange("Category 2")}>Category 2</button>
        {/* Add more buttons for additional categories */}
      </div>
      <div className="gallery-images">
        {filteredImages.map((image, index) => (
          <img key={index} src={image.src} alt={image.alt} className="gallery-image" />
        ))}
      </div>
      <Footer />
      </>
  );
};

export default Gallery;
